import React, { useState, useEffect, useRef } from 'react';
import { Alert, Modal, Tabs } from 'flowbite-react';
import axios from '../../utils/axios';
import { object, string, ValidationError } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { MediaSelector, DocumentItem, VideoItem } from './components';
import { UploadComponent } from '../../components';
import { Course, Media, MediaSelection, Section } from '../../types';
import { AxiosError } from 'axios';
import { useMedia } from '../../components/useMedia';
import { customModalTheme } from '../../custom-themes/customModal';
import { customTabTheme } from '../../custom-themes/customTab';
import { useDeleteFlagStore } from '../../utils/store';
import { ToastContainer, toast } from 'react-toastify';
import { imageUrls, MediaType } from '../../utils/constants';
import 'react-toastify/dist/ReactToastify.css';
import { UploadResponse } from '../../components/UploadComponent/types';

const LecturerSection = () => {
  const navigate = useNavigate();
  const setDeletionFlag = useDeleteFlagStore((state: any) => state.setDeletionFlag);
  const mediaDeletionIdRef = useRef<string>('');
  const { courseId, sectionId } = useParams();
  const [uploadError, setUploadError] = useState('');
  const [sectionValidationErrors, setSectionValidationErrors] = useState<Record<string, string>>();
  const [error, setError] = useState('');
  const [course, setCourse] = useState<Course>();
  const [startUpload, setStartUpload] = useState(false);
  const [closable, setClosable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [deletionMediaModalVisibility, setDeletionMediaModalVisibility] = useState(false);
  const [editable, setEditable] = useState(false);
  const section = course?.sections.find((section: Section) => sectionId === section._id);
  const { data: mediaItems } = useMedia(section?.media.map(({ mediaId }) => mediaId) || []);
  const videos = mediaItems?.filter((media: Media) => media.type === 'video');
  const documents = mediaItems?.filter((media: Media) => media.type === 'raw' || media.type === 'image');
  const hasMedia = (videos && videos?.length >= 1) || (documents && documents?.length >= 1);

  const [mediaSelectorState, setMediaSelectorState] = useState({
    loading: false,
    show: false,
  });

  const [mediaForm, setMediaForm] = useState({
    title: '',
  });

  const validationSchema = object({
    title: string().required('A Title Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const handleShow = () => setVisible(true);

  const showDeletionToast = () => {
    toast.success('Media Removed Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'mediaRemoveToast',
    });
  };

  const showAddedMediaToast = () =>
    toast.success('Media Added Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'mediaAddToast',
    });

  const showSectionEditToast = () =>
    toast.success('Section Edited Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'sectionEditToast',
    });

  const showErrorToast = () =>
    toast.error('Sorry There Was An Issue Please Try Again Later', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'errorToast',
    });

  const handleUploadError = (err?: string) => {
    if (err) {
      setUploadError(err);
    } else {
      showErrorToast();
    }
    setClosable(true);
    setStartUpload(false);
  };

  const handleClose = () => {
    setMediaForm({ title: '' });
    setUploadError('');
    setVisible(false);
    setStartUpload(false);
  };

  const showMediaSelector = () => {
    setMediaSelectorState({ ...mediaSelectorState, show: true });
  };

  const closeMediaSelector = () => {
    setMediaSelectorState({ ...mediaSelectorState, show: false });
  };

  const finalizeMediaSelection = async (mediaObj: MediaSelection) => {
    if (!sectionId) {
      return;
    }

    const selectedMediaItems = Object.values(mediaObj).map((item) => {
      return {
        id: item.id,
        order: 999,
      };
    });
    try {
      setMediaSelectorState({ ...mediaSelectorState, loading: true });
      const promises = selectedMediaItems.map(
        async (mediaItem) =>
          await axios.patch('/courses/sections/media', {
            courseId,
            sectionId,
            mediaId: mediaItem.id,
            order: mediaItem.order,
          })
      );
      await Promise.all(promises);
      getSection();
      setMediaSelectorState({ ...mediaSelectorState, loading: false });
      showAddedMediaToast();
    } catch (err) {
      setMediaSelectorState({ ...mediaSelectorState, loading: false });
      showErrorToast();
    }
  };

  const handleUpload = () => {
    if (mediaForm.title.length < 1) {
      setUploadError('Title Is Required');
    } else {
      setUploadError('');
      setStartUpload(true);
      setClosable(false);
    }
  };

  const handleChange = (e: any) => {
    setMediaForm({
      ...mediaForm,
      [e.target.name]: e.target.value,
    });
  };

  const removeSectionMedia = async (mediaId: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/courses/sections/media`, {
        data: {
          courseId,
          sectionId,
          mediaId: mediaId,
        },
      });
      mediaDeletionIdRef.current = '';
      await getSection();
      showDeletionToast();
    } catch (err) {
      showErrorToast();
      mediaDeletionIdRef.current = '';
    }
  };

  const deleteSections = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/courses/sections`, {
        data: {
          courseId,
          sectionId,
        },
      });
      setDeletionFlag(true);
      navigate(`/lecturer/courses/${courseId}`);
    } catch (err) {
      showErrorToast();
    }
  };

  const getSection = async () => {
    try {
      const res = await axios.get(`/courses/${courseId}`);
      setCourse(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const finalizeMediaUpload = async (cloudObj: UploadResponse) => {
    const mediaData = {
      title: mediaForm.title,
      url: cloudObj.url,
      type: cloudObj.type,
      size: Number(cloudObj.size),
    };
    try {
      await axios.post(`/media`, mediaData).then(async (res) => {
        const sectionMedia = {
          courseId: courseId,
          sectionId: sectionId,
          mediaId: res.data.id,
          order: 1,
        };
        await axios.patch('/courses/sections/media', sectionMedia);
        getSection();
        showAddedMediaToast();
        setClosable(true);
        handleClose();
      });
    } catch (err) {
      showErrorToast();
      setClosable(true);
      setStartUpload(false);
    }
  };

  const editSection = async (e: any) => {
    e.preventDefault();
    setSectionValidationErrors({});
    const formData = {
      title: e.target.elements['title'].value,
      description: e.target.elements['description'].value,
    };
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const sectionPatchRequest = {
        courseId: courseId,
        sectionId: sectionId,
        title: formData.title,
        description: formData.description,
      };
      await axios.patch('/courses/sections', sectionPatchRequest);
      setEditable(false);
      getSection();
      showSectionEditToast();
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setSectionValidationErrors(formErrors);
      } else {
        showErrorToast();
      }
    }
  };

  useEffect(() => {
    getSection();
  }, []);

  return (
    <div>
      <div className="flex flex-col bg-white rounded shadow-sm page-card-container">
        <div className="flex content-center justify-between m-3 rounded bg-dblue">
          <div className="p-8">
            <h1 className="self-center pb-2 mb-0 text-2xl font-bold text-white capitalize font-lato">
              {course?.title}
            </h1>
            <p className="mb-0 text-lg font-medium text-white capitalize font-lato">{section?.title}</p>
          </div>
          {hasMedia ? (
            <div className="flex">
              <button className="self-center custom-secondary-section-btn me-2" onClick={handleShow}>
                Upload
              </button>
              <button className="self-center me-8 custom-primary-section-btn" onClick={showMediaSelector}>
                Add New
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex-grow px-6 pb-6">
          <Tabs aria-label="Tabs with underline" style="underline" className="h-full border-0" theme={customTabTheme}>
            <Tabs.Item active title="Section Media">
              {error && (
                <Alert color="failure" className="mb-4">
                  <p className="font-medium font-lato">{error}</p>
                </Alert>
              )}
              {hasMedia ? (
                <div className="grid gap-8">
                  {documents && documents.length >= 1 ? (
                    <div>
                      <h5 className="pb-2 mb-0 text-lg font-medium font-lato">Documents</h5>
                      <table className="w-full border-b border-collapse table-auto border-x border-slate-100">
                        <thead className="border-0 bg-dashboard">
                          <tr>
                            <th>
                              <p className="py-3 mb-0 text-base font-medium font-poppins ps-6 text-start">File Name</p>
                            </th>
                            <th>
                              <p className="py-3 mb-0 text-base font-medium font-poppins text-start">File Size</p>
                            </th>
                            <th>
                              <p className="py-3 mb-0 text-base font-medium font-poppins text-start">Date Uploaded</p>
                            </th>
                            <th className="py-3 custom-table-cell-width"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((document) => (
                            <DocumentItem
                              key={`media-${document._id}-column`}
                              media={document}
                              onDelete={() => {
                                mediaDeletionIdRef.current = document._id;
                                setDeletionMediaModalVisibility(true);
                              }}
                              link={`/lecturer/courses/${courseId}/section/${sectionId}/view/${document?._id}`}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                  {videos && videos.length >= 1 ? (
                    <div>
                      <h5 className="pb-6 mb-0 text-lg font-medium font-lato">Videos</h5>
                      {videos.map((video) => (
                        <VideoItem
                          key={`media-${video._id}-column`}
                          media={video}
                          link={`/lecturer/courses/${courseId}/section/${sectionId}/view/${video?._id}`}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="flex flex-col content-center justify-center h-full">
                  <img
                    src={imageUrls.EmptyState}
                    alt="Box"
                    className="mx-auto d-block pb-2 h-48"
                    onClick={handleShow}
                  />
                  <p className="text-center font-lato text-base font-medium capitalize mb-1">No Media Available</p>
                  <p className="text-center font-lato text-sm font-normal capitalize text-slate-400 mb-6">
                    Sorry, there is no content here at the moment.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="text-base rounded font-poppins h-14 w-36 custom-primary-btn me-2"
                      onClick={handleShow}
                    >
                      Upload
                    </button>
                    <button
                      className="text-base rounded font-poppins h-14 w-36 custom-secondary-btn"
                      onClick={showMediaSelector}
                    >
                      Add Media
                    </button>
                  </div>
                </div>
              )}
            </Tabs.Item>
            <Tabs.Item title="Settings">
              <div className="w-full lg:w-1/2">
                <form onSubmit={editSection}>
                  <label htmlFor="title" className="block pb-4 text-lg font-normal font-lato text-slate-400">
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className={`form-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
                    defaultValue={section?.title}
                    disabled={!editable}
                  />
                  {sectionValidationErrors?.title && (
                    <p className="text-base text-red-600 font-lato">{sectionValidationErrors.title}</p>
                  )}
                  <label htmlFor="description" className="block pt-6 pb-4 text-lg font-normal font-lato text-slate-400">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    defaultValue={section?.description}
                    className={`textarea-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
                    disabled={!editable}
                  />
                  {sectionValidationErrors?.description && (
                    <p className="text-base text-red-600 font-lato">{sectionValidationErrors.description}</p>
                  )}
                  <div className="pt-6">
                    {editable ? (
                      <div>
                        <button
                          className="h-12 font-bold rounded me-4 custom-primary-btn font-poppins w-36"
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          className="h-12 font-bold rounded custom-secondary-btn font-poppins w-36"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditable(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="h-12 font-bold text-red-500 border border-red-500 rounded me-4 hover:text-white hover:bg-red-500 font-poppins w-36"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeletionModalVisibility(true);
                          }}
                        >
                          Delete
                        </button>
                        <button
                          className="h-12 font-bold rounded custom-primary-btn font-poppins w-36"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditable(true);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </Tabs.Item>
          </Tabs>
        </div>
      </div>

      <Modal show={visible} onClose={handleClose} size="md" position="center" theme={customModalTheme}>
        <Modal.Header className="justify-center border-0">
          <h3 className="font-bold font-lato">Upload A New Media File</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <form>
            <label htmlFor="title" className="block pb-2 text-lg font-normal font-lato">
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={mediaForm.title}
              onChange={handleChange}
              className="mb-2 border form-input-fields border-slate-500"
              required
            ></input>
            <UploadComponent
              mediaType={MediaType.VIDEO}
              onError={handleUploadError}
              onSuccess={finalizeMediaUpload}
              startUpload={startUpload}
              filename={mediaForm.title}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={handleClose}
            disabled={!closable}
          >
            Close
          </button>

          <button
            className="w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins"
            onClick={handleUpload}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deletionModalVisibility}
        onClose={() => setDeletionModalVisibility(false)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Body>
          <h1 className="text-2xl font-medium text-center capitalize font-lato">
            Are you sure you want to delete this section ?
          </h1>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-secondary-btn font-poppins"
            onClick={() => setDeletionModalVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-primary-btn font-poppins"
            onClick={() => deleteSections()}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <MediaSelector
        loading={mediaSelectorState.loading}
        onHide={closeMediaSelector}
        onSelect={finalizeMediaSelection}
        show={mediaSelectorState.show}
      />

      <Modal
        show={deletionMediaModalVisibility}
        onClose={() => setDeletionMediaModalVisibility(false)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Body>
          <h1 className="mb-3 text-2xl font-medium text-center capitalize font-lato">
            Are you sure you want to remove this media item ?
          </h1>
          <p className="text-base font-medium text-center capitalize font-lato">
            (This wont permanently delete it from studyitlive)
          </p>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-secondary-btn font-poppins"
            onClick={() => setDeletionMediaModalVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-primary-btn font-poppins"
            onClick={() => removeSectionMedia(mediaDeletionIdRef.current)}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer containerId="mediaRemoveToast" />
      <ToastContainer containerId="mediaAddToast" />
      <ToastContainer containerId="sectionEditToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};
export default LecturerSection;
