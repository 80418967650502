import React, { useEffect, useState } from 'react';
import { ListGroup, Modal } from 'flowbite-react';
import axios from '../../../utils/axios';
import { Media, MediaSelection } from '../../../types';
import { customModalTheme } from '../../../custom-themes/customModal';

type Props = {
  show: boolean;
  loading: boolean;
  onHide: () => void;
  onSelect: (data: MediaSelection) => void;
};

const MediaSelector = ({ loading, onHide, onSelect, show }: Readonly<Props>) => {
  const [mediaFiles, setMediaFiles] = useState<Media[]>([]);
  const [selection, setSelection] = useState<{ [key: string]: Media }>({});

  const toggleSelection = (media: Media) => {
    const temp = { ...selection };
    if (media.id in selection) {
      delete temp[media.id];
    } else {
      temp[media.id] = media;
    }
    setSelection(temp);
  };

  const handleSelect = () => {
    onSelect(selection);
  };

  const handleClose = () => {
    setSelection({});
    onHide();
  };

  useEffect(() => {
    const fetchMediaFiles = async () => {
      const res = await axios.get('/media');
      setMediaFiles(res.data);
    };

    if (show) {
      fetchMediaFiles();
    }
  }, [show]);

  return (
    <Modal show={show} onClose={handleClose} size="lg" position="center" theme={customModalTheme}>
      <Modal.Header className="border-0 justify-center">
        <span className="font-lato font-bold">Select media file(s)</span>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <ListGroup>
          {mediaFiles.map((item) => {
            return (
              <ListGroup.Item active={item.id in selection} key={item.id} onClick={() => toggleSelection(item)}>
                <p className="mb-0 inline">{item.title}</p>
                <div className="bg-primary90 text-white inline rounded-md px-2 py-1 ms-auto">{item.type}</div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          className="custom-secondary-btn h-10 rounded w-full font-poppins text-lg font-medium"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </button>
        <button
          className="custom-primary-btn h-10 rounded w-full font-poppins text-lg font-medium"
          onClick={handleSelect}
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MediaSelector;
