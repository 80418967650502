import React from 'react';
import { RouteProps, Navigate, Outlet } from 'react-router-dom';
import { useUserTypeStore } from '../utils/store';
import { getTokenPair } from '../utils/cookies';
import { UserType } from '../types';

type ProtectedRouteProps = RouteProps & {
  allowedRoles: UserType[];
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { accessToken, refreshToken } = getTokenPair();
  const isAuthenticated = accessToken && refreshToken;
  const userRole = useUserTypeStore((state: any) => state.userType) as UserType;
  const isAllowed = isAuthenticated && (props.allowedRoles.length === 0 || props.allowedRoles.includes(userRole));

  if (!isAllowed) return <Navigate to={'/login'} />;

  return props.children ? <>{props.children}</> : <Outlet />;
};

export default ProtectedRoute;
