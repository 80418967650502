import React from 'react';
import { Tabs } from 'flowbite-react';
import { Profile } from '../../components';
// import { ProfileNotifications } from '../../components';
import { customTabTheme } from '../../custom-themes/customTab';

const StudentProfile = () => {
  return (
    <div>
      <div className="bg-white rounded page-card-container shadow">
        <div className="m-6">
          <h1 className="font-lato text-lg font-semibold mb-0">Profile Settings</h1>
        </div>
        <div className="px-6 pb-6">
          <Tabs aria-label="Tabs with underline" style="underline" className="border-0" theme={customTabTheme}>
            <Tabs.Item active title="Personal Details">
              <Profile />
            </Tabs.Item>
            {/* <Tabs.Item title="Account">
              <ProfileNotifications />
            </Tabs.Item> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
