import { CustomFlowbiteTheme } from 'flowbite-react';

export const customModalTheme: CustomFlowbiteTheme['modal'] = {
  header: {
    close: {
      base: 'hidden',
      icon: 'hidden',
    },
  },
};
