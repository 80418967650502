import { useQuery } from '@tanstack/react-query';
import { ProfileData } from '../types';
import axios from '../utils/axios';
import { imageUrls } from '../utils/constants';

export const ProfilePicture = () => {
  const { data } = useQuery<ProfileData>({
    queryKey: ['profile'],
    queryFn: async () => {
      const res = await axios.get(`/users/me`);
      return res.data;
    },
  });
  const profilePhotoUrl =
    data?.profilePhotoUrl && data.profilePhotoUrl.trim() !== '' ? data.profilePhotoUrl : imageUrls.SilLogo;
  return profilePhotoUrl;
};
