import axios, { AxiosRequestConfig } from 'axios';
import { setTokenPair, getTokenPair } from './cookies';
import { logout } from './utilMethods';

let isRefreshing = false;

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

const axiosOptions = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const instance = axios.create(axiosOptions);

instance.interceptors.request.use(
  async (request) => {
    const accessToken = getTokenPair().accessToken;
    if (accessToken !== undefined && accessToken !== null && request.url !== '/auth/refresh') {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status && [422, 401].includes(error.response.status)) {
      const originalRequest: AxiosRequestConfig = error.config;

      if (originalRequest.url === '/auth/refresh') {
        return await logout();
      }

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const res = await instance.post(
            `/auth/refresh`,
            {},
            {
              headers: {
                Authorization: `Bearer ${getTokenPair().refreshToken}`,
              },
            }
          );
          const { accessToken, refreshToken } = res.data;
          setTokenPair({ accessToken, refreshToken });

          isRefreshing = true;

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            instance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          refreshAndRetryQueue.length = 0;

          return instance(originalRequest);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
