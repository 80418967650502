import React from 'react';
import { PlayCircle, Laptop, CardHeading } from 'react-bootstrap-icons';
import { Fade, Flip, Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { Header, Footer } from '../components';
import { imageUrls } from '../utils/constants';
function Home() {
  return (
    <div>
      <Header />
      <main className="overflow-hidden">
        <section className="lg:pt-24 pt-8 container mx-auto grid gap-4 lg:gap-x-12 lg:grid-cols-2 px-6 md:px-0">
          <Fade direction="left" triggerOnce className="my-auto">
            <div className="pb-8 lg:pb-0 text-center lg:text-start lg:ps-8 2xl:ps-0">
              <h1 className="text-primary90 text-6xl/[1.5] font-bold pb-4 capitalize">Learning Just Got Better</h1>
              <h5 className="font-poppins text-activeText font-normal text-2xl/9 pb-8 capitalize">
                Joing the virtual or hybrid learning structure today and study at your pace
              </h5>
              <Link to="/signup">
                <button className="custom-btn-large custom-primary-btn font-poppins font-medium text-2xl">
                  Register
                </button>
              </Link>
            </div>
          </Fade>
          <Fade direction="right" triggerOnce>
            <div className="mx-auto">
              <img src={imageUrls.studentsReading} alt="Students Reading" />
            </div>
          </Fade>
        </section>

        <section className="bg-red">
          <div className="container mx-auto grid">
            <Flip direction="down" triggerOnce>
              <h3 className="text-center font-poppins text-4xl font-semibold text-headerText pt-16 md:pt-24 pb-12 capitalize">
                Activate Your Academic And Professional Journey Here.
              </h3>
            </Flip>
            <div className="grid gap-4 lg:grid-cols-3 pb-24">
              <Flip direction="down" triggerOnce>
                <div className="flex justify-center pb-4 lg:pb-0">
                  <div className="bg-accent2 rounded-full w-20 h-20 me-4 flex items-center justify-center">
                    <PlayCircle color="blue" size={35} />
                  </div>
                  <span className="self-center font-poppins text-2xl font-medium capitalize">Online Tutoring</span>
                </div>
              </Flip>
              <Flip direction="down" triggerOnce>
                <div className="flex justify-center pb-4 lg:pb-0">
                  <div className="bg-accent1 rounded-full w-20 h-20 me-4 flex items-center justify-center">
                    <Laptop color="white" size={35} />
                  </div>

                  <span className="self-center font-poppins text-2xl font-medium capitalize">Active Learning</span>
                </div>
              </Flip>
              <Flip direction="down" triggerOnce>
                <div className="flex justify-center">
                  <div className="bg-accent3 rounded-full w-20 h-20 me-4 flex items-center justify-center">
                    <CardHeading color="green" size={35} />
                  </div>

                  <span className="self-center font-poppins text-2xl font-medium capitalize">Certify Yourself</span>
                </div>
              </Flip>
            </div>
          </div>
        </section>
        <section className="container mx-auto grid px-6 md:px-0">
          <div className="grid lg:grid-cols-2 lg:pt-24 pt-16 pb-28 lg:gap-x-12">
            <div className="flex justify-center items-center pb-8 lg:pb-0">
              <img src={imageUrls.boyDesktop} alt="Boy Using Desktop" />
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <h2 className="font-poppins font-semibold text-5xl/[1.5] text-primary90 pb-4 capitalize text-center lg:text-start">
                Learning Has Never Been Easier
              </h2>
              <p className="font-poppins font-normal text-2xl/[1.4] text-activeText pb-6 capitalize text-center lg:text-start">
                Join an integrated learning community of scholars and industry experts
              </p>
              <ul className="pl-0 pb-8 lg:text-start">
                <li className="list-checkmark font-poppins font-medium text-base text-activeText mb-3 capitalize">
                  Join a live class
                </li>
                <li className="list-checkmark font-poppins font-medium text-base text-activeText mb-3 capitalize">
                  Get materials during live class and also after the class
                </li>
                <li className="list-checkmark font-poppins font-medium text-base text-activeText capitalize">
                  Get materials during live class and also after the class
                </li>
              </ul>
              <div className="flex lg:justify-normal justify-center items-center">
                <Link to="/signup" className="text-decoration-none">
                  <button className="custom-btn-large custom-primary-btn font-poppins font-medium text-2xl">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3">
            <div className="flex justify-center">
              <img src={imageUrls.BoyPack} alt="Boy With Backpack" />
            </div>
            <div className="flex justify-center">
              <img src={imageUrls.GirlTree} alt="Girl Reading Infront Of Tree" />
            </div>
            <div className="flex justify-center">
              <img src={imageUrls.BoyLap} alt="Boy Using Laptop" />
            </div>
          </div>
        </section>
        <section className="bg-home-image mt-16">
          <Flip direction="down" triggerOnce>
            <h3 className="text-center font-poppins font-semibold text-4xl text-white pt-28 pb-4 capitalize">
              Join us today
            </h3>
            <h4 className="text-center font-poppins font-medium text-base text-white pb-8 capitalize">
              1000+ Million People Are Already Learning On Our Platform
            </h4>
            <div className="flex justify-center pb-28">
              <Link to="/signup" className="text-decoration-none">
                <button className="custom-btn-large custom-secondary-btn font-poppins font-medium text-lg">
                  Join For Free
                </button>
              </Link>
            </div>
          </Flip>
        </section>
        <section className="bg-grey">
          <div className="container mx-auto pt-24 lg:pt-40 px-6 md:px-0">
            <div className="grid lg:grid-cols-2 lg:pb-32 pb-8 gap-x-16">
              <Slide direction="left" triggerOnce>
                <div className="pb-16 lg:pb-0 flex justify-center items-center">
                  <img src={imageUrls.Frame1} alt="Students Doing Academic Activties" />
                </div>
              </Slide>

              <Slide direction="right" triggerOnce>
                <div className="my-auto pb-16 lg:pb-0 text-center lg:text-start">
                  <h2 className="font-poppins font-semibold text-5xl text-primary90 pb-4 capitalize">Who we are</h2>
                  <p className="font-poppins font-medium text-2xl/10 capitalize">
                    We are on a mission to help students and lecturers develop better mutual understanding woven around
                    a well-tested strategy that assures that learning becomes a more realistic, updated and sweet
                    experience.
                  </p>
                </div>
              </Slide>
            </div>
            <div className="grid lg:grid-cols-2 gap-x-16 pb-0 lg:pb-32">
              <Slide direction="left" triggerOnce>
                <div className="my-auto pb-16 lg:pb-0 order-last lg:order-first text-center lg:text-end">
                  <h2 className="font-poppins font-semibold text-5xl text-primary90 pb-4 capitalize">At StudyItLive</h2>
                  <p className="font-poppins font-medium text-2xl/10 capitalize">
                    We envision a world where learning and teaching is an actuality of today&apos;s social, corporate,
                    and economic expectations. Begin your bespoke learning journey today by accessing on-demand lecture
                    videos and materials.
                  </p>
                </div>
              </Slide>

              <Slide direction="right" triggerOnce>
                <div className="flex justify-center items-center pb-16 lg:pb-0 ">
                  <img src={imageUrls.TeacherZoom} alt="A Man On A Video Call" />
                </div>
              </Slide>
            </div>

            <div className="grid lg:grid-cols-2 gap-x-16 pb-32">
              <Slide direction="left" triggerOnce>
                <div className="pb-16 lg:pb-0 flex justify-center items-center">
                  <img src={imageUrls.TeacherBoard} alt="A Man Infront Of A Projected Image" />
                </div>
              </Slide>

              <Slide direction="right" triggerOnce>
                <div className="my-auto text-center lg:text-start">
                  <h2 className="font-poppins font-semibold text-5xl/[1.3] text-primary90 pb-4 lg:pb-8 capitalize">
                    StudyItLive is more than a learning platform
                  </h2>
                  <p className="font-poppins font-medium text-2xl/10 capitalize">
                    It is a companion that will not let you bear the academic burdens alone. This relationship will walk
                    you through the steps to your academic and professional growth.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </section>

        <section className="container mx-auto pb-36">
          <div className="grid pt-24">
            <Fade triggerOnce>
              <div className="text-center pb-16">
                <h3 className="font-poppins font-semibold text-5xl/[1.3] text-primary90 pb-2 capitalize">
                  From the StudyItLive Lecturers
                </h3>
                <h4 className="font-poppins font-medium text-2xl capitalize">
                  1000+ million people are already learning on StudyItLive
                </h4>
              </div>
            </Fade>
            <div className="grid lg:grid-cols-3 gap-3">
              <Fade direction="left" triggerOnce>
                <div className="testimonial-card mx-auto">
                  <div className="pt-7">
                    <img
                      src={imageUrls.User1}
                      alt="A Man Using A Cell Phone"
                      className="rounded-full mx-auto w-36 h-36"
                    />
                  </div>
                  <div className="p-3 text-center">
                    <h3 className="font-poppins navy-blue-color font-medium capitalize">Demola Ishola</h3>
                    <p className="font-poppins font-semibold pb-1 mb-0 capitalize">Product Manager</p>
                    <small className="font-poppins font-medium text-base/[1.3] capitalize">
                      Recruiters saw my certificate on my LinkedIn Profile and were impressed with my skills I learned.
                      I got the job
                    </small>
                  </div>
                </div>
              </Fade>
              <Fade direction="down" triggerOnce>
                <div className="testimonial-card mx-auto">
                  <div className="pt-7">
                    <img
                      src={imageUrls.User2}
                      alt="A Man Using A Laptop"
                      className="rounded-full mx-auto mt-3 w-36 h-36"
                    />
                  </div>
                  <div className="p-3 text-center">
                    <h3 className="font-poppins navy-blue-color font-medium capitalize">Wahab Ayomide</h3>
                    <p className="font-poppins font-semibold pb-1 mb-0 capitalize">Full-Stack Developer</p>
                    <small className="font-poppins font-medium text-base/[1.3] capitalize">
                      My employer was super excited with how much have learnt on the job and got a raise. StudyItLive
                      helped me track my progress.
                    </small>
                  </div>
                </div>
              </Fade>
              <Fade direction="right" triggerOnce>
                <div className="testimonial-card mx-auto">
                  <div className="pt-7">
                    <img
                      src={imageUrls.User3}
                      alt="A Woman Writing In A Notebook"
                      className="rounded-full mx-auto mt-3 w-36 h-36"
                    />
                  </div>
                  <div className="p-3 text-center">
                    <h3 className="font-poppins navy-blue-color font-medium capitalize">Amood Sumayyah</h3>
                    <p className="font-poppins font-semibold pb-1 mb-0 capitalize">UX Designer</p>
                    <small className="font-poppins font-medium text-base/[1.3] capitalize">
                      I started at ground zero. With StudyItLive I was able to start learning UX online and eventually
                      build up enough knowledge to transition into a well paying career.
                    </small>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
export default Home;
