import { Flowbite, Tabs } from 'flowbite-react';
import StudentCourseBrowser from './StudentCourseBrowser';
import StudentPersonalCourses from './StudentPersonalCourses';
import React from 'react';
import { customTabTheme } from '../../custom-themes/customTab';

const StudentCourses = () => {
  return (
    <div className="h-full">
      <Tabs aria-label="Tabs with underline" style="underline" className="border-0 h-full" theme={customTabTheme}>
        <Tabs.Item active title="Explore Courses">
          <StudentCourseBrowser />
        </Tabs.Item>
        <Tabs.Item title="My Courses">
          <StudentPersonalCourses />
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default StudentCourses;
