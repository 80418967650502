import { useQuery } from '@tanstack/react-query';
import axios from '../utils/axios';
import { Course } from '../types';

export const useCourse = (id: string) => {
  return useQuery<Course, Error>({
    queryKey: ['course'],
    queryFn: async () => {
      const res = await axios.get(`/courses/${id}`);
      const data = res.data;
      return data;
    },
  });
};
