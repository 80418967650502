import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import { Course, Section } from '../../types';
import { useNavigate } from 'react-router-dom';

function StudentCourse() {
  const { courseId } = useParams();
  const [course, setCourse] = useState<Course>();
  const [sections, setSections] = useState<Section[]>([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const submitPayment = async () => {
    navigate(`/student/course/${courseId}/view`);
  };

  const getCourse = async () => {
    try {
      const res = await axios.get(`/courses/${courseId}`);
      setCourse(res.data);
      setSections(res.data.sections);
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="page-card-container bg-white rounded shadow-sm">
      <div className="px-4 py-6 m-0 flex justify-between content-center">
        <h1 className="self-center font-lato text-black text-3xl font-bold mb-0 capitalize">{course?.title}</h1>
        <button className="self-center custom-secondary-section-btn" onClick={() => submitPayment()}>
          Enroll
        </button>
      </div>
      <div className="px-4 pb-6">
        <img src={course?.photoUrl} alt="Course-Image" className="w-full h-[400px] rounded" />
        <div className="mb-0 pt-4">
          <p className="font-lato font-bold text-xl mb-2">Course Description</p>
          <p className="font-lato font-medium text-base text-gray-400 mb-4">{course?.description}</p>
          <p className="font-lato font-bold text-xl mb-2">Course Material</p>
          {sections.length >= 1 ? (
            <ul className="list-disc pl-6">
              {sections.map((section: Section, index: number) => (
                <li key={`section-${section._id}-column`}>
                  <p className="font-lato font-medium text-base text-gray-400">
                    Lesson {index + 1}: &nbsp;{section.title}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
export default StudentCourse;
