import React from 'react';
import { imageUrls } from '../utils/constants';
const Footer = () => {
  return (
    <footer className="bg-primaryDark">
      <div className="container mx-auto grid">
        <div className="grid lg:grid-cols-4 pt-16 pb-24 gap-3">
          <div>
            <img src={imageUrls.FooterLogo} alt="Studyitlive Logo" />
          </div>

          <div className="text-white">
            <p className="font-poppins font-medium text-lg">Blog</p>
            <p className="font-poppins font-medium text-lg">Terms</p>
            <p className="font-poppins font-medium text-lg">Career</p>
            <p className="font-poppins font-medium text-lg">About us</p>
          </div>
          <div className="text-white">
            <p className="font-poppins font-medium text-lg">Contact us</p>
            <p className="font-poppins font-medium text-lg">Privacy policy</p>
            <p className="font-poppins font-medium text-lg">Teach on StudyItLive</p>
            <p className="font-poppins font-medium text-lg">Help and support</p>
          </div>
          <div>
            <label htmlFor="newsletter" className="font-lato text-lg font-semibold text-white block mb-2">
              Subscribe To Our Newsletter
            </label>
            <input
              type="text"
              placeholder="Enter your email"
              className="rounded w-full h-12 text-black indent-4 mb-4"
            />
            <button className="custom-btn custom-primary-btn font-poppins text-base font-medium">Subscribe</button>
          </div>
        </div>
        <div className="border-t border-white">
          <div className="flex pt-10 pb-16">
            <span className="text-2xl text-white me-1">&copy;</span>
            <p className="font-poppins text-sm font-medium text-white self-center mb-0">
              {new Date().getFullYear()} STUDYitLIVE. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
