import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate, formatFileSize } from '../../../utils/utilMethods';
import { Media } from '../../../types';
import { imageUrls } from '../../../utils/constants';

export const VideoItem = ({ media, link }: { media: Media; link: string }) => {
  return (
    <Link
      key={`media-${media?._id}-column`}
      to={{
        pathname: link,
      }}
      className="no-underline"
    >
      <div className="flex pb-3 border-b-2">
        <img src={imageUrls.VideoThumbNail} alt="Box" className="rounded shrink h-24 w-32" />
        <div className="grow ms-3">
          <h5 className="text-xl font-semibold text-black capitalize font-lato">{media?.title}</h5>
        </div>
      </div>
    </Link>
  );
};

export const DocumentItem = ({ media, link, onDelete }: { media: Media; link: string; onDelete: () => void }) => {
  return (
    <tr className="border-b-2">
      <td className="flex items-center py-2 ms-2">
        <img src={imageUrls.DocumentIcon} alt="Document Icon" className="shrink" />
        <Link
          key={`media-${media?._id}-column`}
          to={{
            pathname: link,
          }}
          className="no-underline"
        >
          <p className="mb-0 text-base font-medium capitalize grow ms-2 font-lato">{media.title}</p>
        </Link>
      </td>
      <td className="py-2">
        <p className="mb-0 text-base font-medium grow font-lato">{formatFileSize(Number(media.size))}</p>
      </td>
      <td className="py-2">
        <p className="mb-0 text-base font-medium grow font-lato">{formatDate(media.createdAt)}</p>
      </td>
      <td className="py-2">
        <img src={imageUrls.TrashIcon} alt="Trash Icon" className="cursor-pointer" onClick={onDelete} />
      </td>
    </tr>
  );
};
