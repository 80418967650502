import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FormCarousel from '../components/FormCarousel';
import { UserType } from '../types';
import axios from '../utils/axios';
import { useUserTypeStore } from '../utils/store';
import { setTokenPair } from '../utils/cookies';
import { Alert } from 'flowbite-react';
import { object, string, ValidationError } from 'yup';
import { AxiosError } from 'axios';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import { imageUrls } from '../utils/constants';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [submissionErrors, setSubmissionErrors] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>();
  const [successVerificationMessage, setSuccessVerificationMessage] = useState<string>('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  const token = searchParams.get('token');
  const setUserType = useUserTypeStore((state: any) => state.setUserType);

  const validationSchema = object({
    email: string().required('Email Is Required').email('Invalid Email Format'),
    password: string().required('Password Is Required'),
  });

  const verifyUser = async () => {
    try {
      const res = await axios.get(`auth/verify`, { params: { token } });
      setSuccessVerificationMessage('Account Verified Successfully');
    } catch (error) {
      if (error instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Verify Your Account';
        const errorMessage: string = error.response ? error.response.data.message : defaultErrorMessage;
        setSubmissionErrors(errorMessage);
      } else {
        setSubmissionErrors(error.message);
      }
    }
  };

  const PostUserData = async (event: any) => {
    event.preventDefault();
    setSubmissionErrors('');
    try {
      await validationSchema.validate({ email, password }, { abortEarly: false });
      const res = await axios.post(`/auth/local/signin`, {
        email: email,
        password: password,
      });
      if (res.status >= 200 && res.status < 400) {
        const { accessToken, refreshToken, roles } = res.data;
        setTokenPair({ accessToken, refreshToken });
        setUserType(roles[0]);
        if (roles[0] === UserType.Instructor) {
          navigate('/lecturer/courses');
        } else if (roles[0] === UserType.Student) {
          navigate('/student/course');
        }
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setValidationErrors(formErrors);
      } else if (error instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = error.response ? error.response.data.message : defaultErrorMessage;
        setSubmissionErrors(errorMessage);
      } else {
        setSubmissionErrors(error.message);
      }
    }
  };

  useEffect(() => {
    if (token) {
      verifyUser();
    }
  }, []);

  return (
    <div className="grid lg:grid-cols-2 overflow-hidden">
      <div className="hidden lg:block">
        <FormCarousel />
      </div>
      <div className="flex justify-center items-center h-screen">
        <div className="w-10/12">
          <img src={imageUrls.SilLogo} alt="Studtitlive Logo" className="mx-auto pb-6 pt-2 lg:pt-0 lg:hidden d-block" />
          <h2 className="font-lato font-bold text-3xl text-center pb-2 capitalize">welcome back!</h2>
          <h6 className="font-lato text-lg capitalize text-center pb-4">
            Dont Have an account?&nbsp;
            <Link to="/signup" className="text-decoration-none dark-blue-color font-lato font-semibold text-lg">
              Sign Up
            </Link>
          </h6>
          <div className="mx-auto w-2/4 text-center">
            {submissionErrors && (
              <Alert color="failure" className="mb-4">
                <p className="font-medium font-lato mx-auto">{submissionErrors}</p>
              </Alert>
            )}
            {successVerificationMessage && (
              <Alert color="success" className="mb-4">
                <p className="font-medium font-lato">{successVerificationMessage}</p>
              </Alert>
            )}
          </div>
          <form className="pb-12">
            <label htmlFor="email" className="block font-lato text-lg font-normal pb-2">
              E-mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input-fields border border-slate-500"
              required
            ></input>
            {validationErrors?.email && <p className="text-red-600 font-lato text-base">{validationErrors.email}</p>}

            <label htmlFor="password" className="block font-lato text-lg font-normal pb-2 pt-4">
              Password
            </label>
            <div className="relative">
              <div
                className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer"
                onClick={() => setTogglePassword(!togglePassword)}
              >
                {togglePassword ? <EyeSlash size={20} color="#8692A6" /> : <Eye size={20} color="#8692A6" />}
              </div>
              <input
                type={togglePassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-input-fields border border-slate-500 pr-10"
                required
              ></input>
              {validationErrors?.password && (
                <p className="text-red-600 font-lato text-base">{validationErrors.password}</p>
              )}
            </div>

            <div className="flex justify-between mb-4 mt-4">
              <label className="capitalize font-lato text-lg font-semibold">
                <input
                  type="checkbox"
                  name="rememberBox"
                  id="rememberBox"
                  onChange={() => setRememberUser(!rememberUser)}
                  className="me-2"
                />
                Remember Me
              </label>

              <Link
                to="/password-reset"
                className="no-underline dark-blue-color font-lato text-lg font-semibold text-end"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              onClick={(e) => PostUserData(e)}
              className="custom-primary-btn w-full h-12 rounded font-poppins text-xl font-medium"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
