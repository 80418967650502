import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'react-bootstrap-icons';
import { imageUrls } from '../utils/constants';

const Header = () => {
  const [navbarToggle, setNavbarToggle] = useState(false);

  return (
    <nav className="bg-white md:py-5 shadow">
      <div className="lg:flex lg:justify-around lg:pb-0">
        <div className="pb-2 md:pb-0 flex justify-between">
          <img src={imageUrls.HeaderLogo} />
          <button className="lg:hidden" type="button" onClick={() => setNavbarToggle(!navbarToggle)}>
            <List size={25} color="black" />
          </button>
        </div>

        <div className="md:space-x-10 space-y-3 md:self-center hidden lg:block">
          <span className="block md:inline-block font-poppins text-lg">Home</span>
          <span className="block md:inline-block font-poppins text-lg">Features</span>
          <span className="block md:inline-block font-poppins text-lg">Blog</span>
        </div>

        <div
          className={`${navbarToggle ? 'hidden' : ''} flex space-x-4 py-3.5 lg:py-0 justify-center lg:justify-normal`}
        >
          <Link to="/login">
            <button className="custom-btn custom-secondary-btn font-poppins text-lg">Login</button>
          </Link>
          <Link to="/signup">
            <button className="custom-btn custom-primary-btn font-poppins text-lg">Join</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default Header;
