import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoComponent from '../../../components/LogoComponent';
import MenuItem from '../../../components/MenuItem';
import { HelpIcon, ScheduleIcon, UserIcon, LogoutIcon, OverviewIcon, CourseIcon } from '../../../assets/svg-components';
import { logout } from '../../../utils/utilMethods';

const SideNav = () => {
  const { pathname } = useLocation();

  const handleLogOut = async () => {
    await logout();
  };

  return (
    <div className="sticky top-0 h-screen bg-white overflow-auto shadow-sm">
      <div className="mt-4 mb-16">
        <LogoComponent />
      </div>
      <div className="mx-1">
        {/* <Link className="no-underline" to="/student/overview">
          <MenuItem title="Dashboard" icon={OverviewIcon} active={pathname === '/student/overview'} />
        </Link> */}
        <Link className="no-underline" to="/student/course">
          <MenuItem title="Courses" icon={CourseIcon} active={/^\/student\/course(\/|$)/.test(pathname)} />
        </Link>
        {/* <Link className="no-underline" to="/student/schedule">
          <MenuItem title="Schedule" icon={ScheduleIcon} active={pathname === '/student/schedule'} />
        </Link> */}
        <Link className="no-underline" to="/student/profile">
          <MenuItem title="Profile" icon={UserIcon} active={pathname === '/student/profile'} />
        </Link>
        <hr />
        {/* <Link className="no-underline" to="/student/help">
          <MenuItem title="Help" icon={HelpIcon} active={pathname === '/student/help'} />
        </Link> */}
        <div onClick={handleLogOut} className="custom-pointer">
          <MenuItem active={false} title="LogOut" icon={LogoutIcon} />
        </div>
      </div>
    </div>
  );
};

export default SideNav;
