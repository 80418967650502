import React from 'react';
import { imageUrls } from '../utils/constants';

const LogoComponent = () => {
  return (
    <div className="ps-8">
      <img src={imageUrls.HeaderLogo} alt="Studyitlive Logo" />
    </div>
  );
};

export default LogoComponent;
