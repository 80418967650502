import { useQuery } from '@tanstack/react-query';
import { ProfileData } from '../types';
import React from 'react';
import axios from '../utils/axios';

export const UserName = () => {
  const { data } = useQuery<ProfileData>({
    queryKey: ['profile'],
    queryFn: async () => {
      const res = await axios.get(`/users/me`);
      return res.data;
    },
  });
  return (
    <>
      {data?.title} {data?.lastName} {data?.firstName}
    </>
  );
};
