/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import axios from '../../utils/axios';
import { Modal, Alert } from 'flowbite-react';
import { formatFileSize, formatDate } from '../../utils/utilMethods';
import { AxiosError } from 'axios';
import { object, string, ValidationError } from 'yup';
import { Media } from '../../types';
import { customModalTheme } from '../../custom-themes/customModal';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { imageUrls, MediaType } from '../../utils/constants';
import { UploadComponent } from '../../components';
import { UploadResponse } from '../../components/UploadComponent/types';

const LecturerMediaLibrary = () => {
  const mediaDeletionIdRef = useRef<string>('');
  const [closable, setClosable] = useState(true);
  const [error, setError] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [startUpload, setStartUpload] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<Media[]>([]);
  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>();
  const [mediaForm, setMediaForm] = useState({
    title: '',
  });

  const validationSchema = object({
    title: string().required('A Title Is Required'),
  });

  const showErrorToast = () =>
    toast.error('Sorry There Was An Issue Please Try Again Later', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'errorToast',
    });

  const showMediaAdditionToast = () =>
    toast.success('Media Uploaded Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'mediaCreationToast',
    });

  const showMediaDeletionToast = () =>
    toast.success('Media Deleted Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'mediaDeletionToast',
    });

  const handleShow = () => setVisible(true);
  const handleUploadError = (err: string) => {
    setClosable(true);
    setUploadError(err);
    setStartUpload(false);
  };

  const handleClose = () => {
    setMediaForm({ title: '' });
    setUploadError('');
    setValidationErrors({});
    setVisible(false);
    setStartUpload(false);
  };

  const handleUpload = async () => {
    setUploadError('');
    try {
      await validationSchema.validate(mediaForm, { abortEarly: false });
      setUploadError('');
      setStartUpload(true);
      setClosable(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setValidationErrors(formErrors);
      }
    }
  };

  const handleChange = (e: any) => {
    setMediaForm({
      ...mediaForm,
      [e.target.name]: e.target.value,
    });
  };

  const getMediaFiles = async () => {
    try {
      const res = await axios.get(`/media`);
      setMediaFiles(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const finalizeUpload = async (cloudObj: UploadResponse) => {
    const mediaData = {
      title: mediaForm.title,
      url: cloudObj.url,
      type: cloudObj.type,
      size: cloudObj.size,
    };
    try {
      await axios.post(`/media`, mediaData);
      await getMediaFiles();
      handleClose();
      showMediaAdditionToast();
    } catch (err) {
      showErrorToast();
    } finally {
      setStartUpload(false);
      setClosable(true);
    }
  };

  const deleteMediaFiles = async (mediaId: string) => {
    try {
      await axios.delete(`/media/${mediaId}`);
      mediaDeletionIdRef.current = '';
      getMediaFiles();
      showMediaDeletionToast();
      setDeletionModalVisibility(false);
    } catch (err) {
      showErrorToast();
      mediaDeletionIdRef.current = '';
    }
  };

  useEffect(() => {
    getMediaFiles();
  }, []);

  return (
    <div>
      <div className="flex flex-col bg-white border-0 shadow-sm page-card-container">
        <div className="flex content-center justify-between m-6">
          <h1 className="self-center mb-0 text-lg font-bold font-lato">Book Shelf</h1>
          {error && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{error}</p>
            </Alert>
          )}
          {mediaFiles.length >= 1 ? (
            <button className="h-12 text-base rounded font-poppins w-36 custom-primary-btn me-2" onClick={handleShow}>
              Upload
            </button>
          ) : null}
        </div>
        <div className="flex-grow px-6 pb-6">
          {mediaFiles.length >= 1 ? (
            <table className="w-full border-b border-collapse table-auto border-x border-slate-100">
              <thead className="border-0 bg-dashboard">
                <tr>
                  <th>
                    <p className="py-3 mb-0 text-base font-medium font-poppins ps-6 text-start">File Name</p>
                  </th>
                  <th>
                    <p className="py-3 mb-0 text-base font-medium font-poppins text-start">File Size</p>
                  </th>
                  <th>
                    <p className="py-3 mb-0 text-base font-medium font-poppins text-start">Date Uploaded</p>
                  </th>
                  <th className="py-3 custom-table-cell-width"></th>
                </tr>
              </thead>
              <tbody>
                {mediaFiles.map((media) => (
                  <tr key={media._id} className="border-b-2">
                    <td className="flex items-center ms-2 py-2">
                      <img src={imageUrls.DocumentIcon} alt="Box" className="shrink" />
                      <Link
                        to={{
                          pathname: `/lecturer/view/${media._id}`,
                        }}
                        className="custom-link"
                      >
                        <p className="mx-2 mb-0 text-base font-medium capitalize grow font-lato">{media.title}</p>
                      </Link>
                    </td>
                    <td className="py-2">
                      <p className="mb-0 text-base font-medium grow font-lato">{formatFileSize(Number(media.size))}</p>
                    </td>
                    <td className="py-2">
                      <p className="mb-0 text-base font-medium grow font-lato">{formatDate(media.createdAt)}</p>
                    </td>
                    <td className="py-2">
                      <img
                        src={imageUrls.TrashIcon}
                        alt="Box"
                        className="cursor-pointer"
                        onClick={() => {
                          mediaDeletionIdRef.current = media.id;
                          setDeletionModalVisibility(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex flex-col content-center justify-center h-full">
              <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-2 h-48" />
              <p className="text-center font-lato text-base font-medium capitalize mb-1">No Media Available</p>
              <p className="text-center font-lato text-sm font-normal capitalize text-slate-400">
                Sorry, there is no content here at the moment.
              </p>
              <p className="mb-6 text-sm font-normal text-center capitalize font-lato text-slate-400">
                Click on the Add button to upload materials.
              </p>
              <div className="flex justify-center">
                <button
                  className="h-12 text-base rounded font-poppins bg-primary w-36 custom-primary-btn"
                  onClick={handleShow}
                >
                  Upload
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={visible} onClose={handleClose} size="md" position="center" theme={customModalTheme}>
        <Modal.Header className="justify-center p-4 border-0">
          <h3 className="font-bold text-center font-lato">Upload A New Media File</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <form>
            <label htmlFor="title" className="block pb-2 text-lg font-normal font-lato">
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={mediaForm.title}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {validationErrors?.title && <p className="text-base text-red-600 font-lato">{validationErrors.title}</p>}
            <div className="pt-4">
              <UploadComponent
                mediaType={MediaType.VIDEO}
                onError={handleUploadError}
                onSuccess={finalizeUpload}
                startUpload={startUpload}
                filename={mediaForm.title}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={handleClose}
            disabled={!closable}
          >
            Close
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins"
            onClick={handleUpload}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deletionModalVisibility}
        onClose={() => setDeletionModalVisibility(false)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Body>
          <h1 className="text-2xl font-medium text-center capitalize font-lato">
            Are you sure you want to delete this media Item ?
          </h1>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-secondary-btn font-poppins"
            onClick={() => setDeletionModalVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-primary-btn font-poppins"
            onClick={() => deleteMediaFiles(mediaDeletionIdRef.current)}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer containerId="mediaDeletionToast" />
      <ToastContainer containerId="mediaCreationToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};

export default LecturerMediaLibrary;
