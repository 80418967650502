import React, { useState } from 'react';
import { Accordion } from 'flowbite-react';
import { Course, Section } from '../../../types';
import { useMedia } from '../../../components';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const StudentCourseProgressView = ({
  courseObject,
  onMediaSelect,
}: {
  courseObject: Course | undefined;
  onMediaSelect: (mediaId: string) => void;
}) => {
  const [activeMediaId, setActiveMediaId] = useState<string | null>(null);
  const sectionList = courseObject?.sections || [];
  const mediaIds = sectionList.map((section) => section.media.map((media) => media.mediaId)).flat();
  const mediaData = useMedia(mediaIds || []).data;

  const handleMediaSelect = (mediaId: string) => {
    setActiveMediaId(mediaId);
    onMediaSelect(mediaId);
  };

  return (
    <Accordion>
      {sectionList.length > 1 ? (
        sectionList.map((section: Section) => {
          const sectionMedia = mediaData?.filter((mediaItem) =>
            section.media.some((media) => media.mediaId === mediaItem.id)
          );
          return (
            <Accordion.Panel key={section._id}>
              <Accordion.Title>
                <div>
                  <p className="font-lato font-semibold text-base mb-0 capitalize">{section.title}</p>
                </div>
              </Accordion.Title>
              <Accordion.Content className="p-0">
                {sectionMedia?.map((mediaItem, index) => {
                  return (
                    <div
                      key={index}
                      className={`border-b border-b-gray-300 py-4 ${
                        mediaItem.id === activeMediaId ? 'bg-accent2' : 'hover:bg-gray-200'
                      }`}
                    >
                      <div key={mediaItem.id} onClick={() => handleMediaSelect(mediaItem.id)}>
                        <div className="ms-6">
                          <p className="mb-1 font-lato font-semibold text-sm capitalize text-gray-700">
                            {index + 1}. {mediaItem.title}
                          </p>
                          <p className="font-poppins font-medium text-xs text-start ms-3.5">{mediaItem.size}mins</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Accordion.Content>
            </Accordion.Panel>
          );
        })
      ) : (
        <Accordion.Panel>
          <Accordion.Title className="hidden"></Accordion.Title>
        </Accordion.Panel>
      )}
    </Accordion>
  );
};
export default StudentCourseProgressView;
