import { CustomFlowbiteTheme } from 'flowbite-react';

export const customTabTheme: CustomFlowbiteTheme['tabs'] = {
  tablist: {
    base: 'flex text-center !h-fit',
    tabitem: {
      styles: {
        underline: {
          active: {
            on: 'active focus:ring-0 border-b-2 border-primary text-[#1d2b4f]',
            off: 'border-b-2 focus:ring-0 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600',
          },
        },
      },
    },
  },
  tabitemcontainer: { base: 'flex-grow' },
  tabpanel: 'py-3 h-full',
};
