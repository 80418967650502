import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { UserType } from '../types';

type UserTypeStore = {
  userType: string | null;
  setUserType: (userType: string | null) => void;
};

type DeletionFlag = {
  dFlag: boolean;
  setDeletionFlag: (dFlag: boolean) => void;
};

export const useUserTypeStore = create<any>(
  persist(
    (set) => ({
      userType: '',
      setUserType: (userType: UserType) => set({ userType: userType }),
    }),
    {
      name: 'userType',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useDeleteFlagStore = create((set) => ({
  dFlag: false,
  setDeletionFlag: (dFlag: boolean) => set({ dFlag: dFlag }),
}));
