import React, { useEffect, useState } from 'react';
import { MediaViewer } from '../../components';
import { AxiosError } from 'axios';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { Media } from '../../types';

const LecturerMediaViewer = () => {
  const [error, setError] = useState('');
  const [mediaObject, setMediaObject] = useState<Media>();
  const { mediaId } = useParams();

  const getMediaObject = async () => {
    try {
      const res = await axios.get(`media/${mediaId}`);
      setMediaObject(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getMediaObject();
  }, []);

  return (
    <div className="bg-white rounded page-card-container">
      <div className="p-6 lg:h-5/6">
        <MediaViewer mediaObject={mediaObject!} />
      </div>
    </div>
  );
};

export default LecturerMediaViewer;
