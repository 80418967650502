import React, { useEffect, useState } from 'react';
import { imageUrls } from '../utils/constants';

const carouselImages = [imageUrls.FirstCarousel, imageUrls.SecondCarousel, imageUrls.ThirdCarousel];

const FormCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => setCurrentIndex(currentIndex === 0 ? carouselImages.length - 1 : currentIndex - 1);

  const handleNext = () => setCurrentIndex(currentIndex === carouselImages.length - 1 ? 0 : currentIndex + 1);

  useEffect(() => {
    const sliderInterval = setInterval(handleNext, 3000);

    return () => clearInterval(sliderInterval);
  }, [currentIndex]);

  return (
    <div className="overflow-hidden relative w-full">
      <div
        className="flex flex-row brightness-50 h-screen w-full transition-transform ease-out duration-700"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {carouselImages.map((image, index) => (
          <img
            src={image}
            alt={`studyItLive carousel image ${index + 1}`}
            key={index}
            className="h-full w-full object-cover flex-shrink-0 cursor-grab"
          />
        ))}
      </div>
      <div className="absolute inset-0 flex flex-col gap-2 items-start justify-center text-white px-20 mt-40">
        <h1 className="text-4xl font-bold 2xl:text-5xl">Start your course from here</h1>
        <p className="w-[65%] text-xl font-medium 2xl:text-2xl">
          Join everest today and start taking classes from the comfort of your home, office, on the go e.t.c
        </p>
        <div className="flex items-center justify-center flex-row gap-2 mt-4">
          {carouselImages.map((_, index) => (
            <div
              className={`transition-all duration-700 h-3 rounded bg-white ${
                currentIndex === index ? 'w-9' : 'w-4 bg-opacity-50'
              }`}
              key={index}
            ></div>
          ))}
        </div>
      </div>
      <div className="absolute inset-0 flex gap-2 items-center justify-between flex-row">
        <button onClick={handlePrevious} className="p-6 rounded h-full">
          {'  '}
        </button>
        <button onClick={handleNext} className="p-6 rounded h-full">
          {'  '}
        </button>
      </div>
    </div>
  );
};

export default FormCarousel;
