import React, { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { EnrollmentData } from '../../../types';
import { currencyFormater } from '../../../utils/utilMethods';
const LecturerCourseEnrollementData = ({ courseId }: { courseId: string | undefined }) => {
  const [enrollmentData, setEnrollmentData] = useState<EnrollmentData>();
  const getEnrollmentData = () => {
    console.log(courseId);
  };

  useEffect(() => {
    getEnrollmentData();
  }, []);

  return (
    <div className="grid gap-4 lg:grid-cols-2 pt-6">
      <div className="lg:col-span-1">
        <p className="font-lato text-base font-normal text-gray-400 pb-2">Total Number Of Enrollments:</p>
        <p className="font-lato font-extrabold text-3xl">{enrollmentData?.totalEnrollments}</p>
      </div>
      <div className="lg:col-span-1">
        <p className="font-lato text-base font-normal text-gray-400 pb-2">Total Earnings:</p>
        <p className="font-lato font-extrabold text-3xl">{currencyFormater(enrollmentData?.totalEarnings)}</p>
      </div>
    </div>
  );
};
export default LecturerCourseEnrollementData;
