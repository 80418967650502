import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNav from './SideNav';
import Header from './Header';

function LecturerLayout() {
  return (
    <div className="grid lg:grid-cols-12 h-screen">
      <div className="hidden lg:block lg:col-span-2">
        <SideNav />
      </div>
      <div className="light-bg-grey lg:col-span-10 flex flex-col">
        <Header />
        <div className="container px-4 pt-6 mx-auto flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default LecturerLayout;
