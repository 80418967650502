import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  LecturerOverview,
  LecturerCourses,
  LecturerCourseContent,
  LecturerMediaLibrary,
  LecturerSchedule,
  LecturerCourse,
  LecturerSection,
  LecturerProfile,
  LecturerHelp,
  LecturerMediaViewer,
} from '../modules/LecturerPortal';
import LecturerLayout from '../modules/LecturerPortal/components/LecturerLayout';
import { ProtectedRoute } from '../components';
import { UserType } from '../types';

const LecturerRoutes = () => {
  return (
    <Routes>
      <Route
        path="/lecturer"
        element={
          <ProtectedRoute allowedRoles={[UserType.Instructor]}>
            <LecturerLayout />
          </ProtectedRoute>
        }
      >
        {/* <Route path="overview" element={<LecturerOverview />} /> */}
        <Route path="courses/:courseId/section/:sectionId/view/:mediaId" element={<LecturerCourseContent />} />
        <Route path="media" element={<LecturerMediaLibrary />} />
        {/* <Route path="schedule" element={<LecturerSchedule />} /> */}
        <Route path="courses/:id" element={<LecturerCourse />} />
        <Route path="courses" element={<LecturerCourses />} />
        <Route path="courses/:courseId/section/:sectionId" element={<LecturerSection />} />
        <Route path="profile" element={<LecturerProfile />} />
        {/* <Route path="help" element={<LecturerHelp />} /> */}
        <Route path="view/:mediaId" element={<LecturerMediaViewer />} />
      </Route>
    </Routes>
  );
};
export default LecturerRoutes;
