import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  StudentCourse,
  StudentCourseViewer,
  StudentCourses,
  StudentOverview,
  StudentProfile,
  StudentSchedule,
  StudentSupport,
} from '../modules/StudentPortal';
import StudentLayout from '../modules/StudentPortal/components/StudentLayout';
import { ProtectedRoute } from '../components';
import { UserType } from '../types';

const StudentRoutes = () => {
  return (
    <Routes>
      <Route
        path="/student"
        element={
          <ProtectedRoute allowedRoles={[UserType.Student]}>
            <StudentLayout />
          </ProtectedRoute>
        }
      >
        {/* <Route path="overview" element={<StudentOverview />} /> */}
        <Route path="course/:courseId" element={<StudentCourse />} />
        <Route path="course" element={<StudentCourses />} />
        <Route path="course/:courseId/view" element={<StudentCourseViewer />} />
        <Route path="profile" element={<StudentProfile />} />
        {/* <Route path="schedule" element={<StudentSchedule />} />
        <Route path="help" element={<StudentSupport />} /> */}
      </Route>
    </Routes>
  );
};
export default StudentRoutes;
