import React from 'react';

const UploadIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 10.3999C0.632608 10.3999 0.759785 10.4526 0.853553 10.5463C0.947322 10.6401 1 10.7673 1 10.8999V13.3999C1 13.6651 1.10536 13.9195 1.29289 14.107C1.48043 14.2945 1.73478 14.3999 2 14.3999H14C14.2652 14.3999 14.5196 14.2945 14.7071 14.107C14.8946 13.9195 15 13.6651 15 13.3999V10.8999C15 10.7673 15.0527 10.6401 15.1464 10.5463C15.2402 10.4526 15.3674 10.3999 15.5 10.3999C15.6326 10.3999 15.7598 10.4526 15.8536 10.5463C15.9473 10.6401 16 10.7673 16 10.8999V13.3999C16 13.9303 15.7893 14.439 15.4142 14.8141C15.0391 15.1892 14.5304 15.3999 14 15.3999H2C1.46957 15.3999 0.960859 15.1892 0.585786 14.8141C0.210714 14.439 0 13.9303 0 13.3999V10.8999C0 10.7673 0.0526784 10.6401 0.146447 10.5463C0.240215 10.4526 0.367392 10.3999 0.5 10.3999Z"
        fill="#828181"
      />
      <path
        d="M7.64566 1.64592C7.6921 1.59935 7.74728 1.56241 7.80802 1.5372C7.86877 1.512 7.93389 1.49902 7.99966 1.49902C8.06542 1.49902 8.13054 1.512 8.19129 1.5372C8.25203 1.56241 8.30721 1.59935 8.35366 1.64592L11.3537 4.64592C11.4475 4.7398 11.5003 4.86714 11.5003 4.99992C11.5003 5.13269 11.4475 5.26003 11.3537 5.35392C11.2598 5.4478 11.1324 5.50055 10.9997 5.50055C10.8669 5.50055 10.7395 5.4478 10.6457 5.35392L8.49966 3.20692V11.9999C8.49966 12.1325 8.44698 12.2597 8.35321 12.3535C8.25944 12.4472 8.13226 12.4999 7.99966 12.4999C7.86705 12.4999 7.73987 12.4472 7.6461 12.3535C7.55233 12.2597 7.49966 12.1325 7.49966 11.9999V3.20692L5.35366 5.35392C5.30717 5.40041 5.25198 5.43728 5.19124 5.46244C5.1305 5.4876 5.0654 5.50055 4.99966 5.50055C4.93391 5.50055 4.86881 5.4876 4.80807 5.46244C4.74733 5.43728 4.69214 5.40041 4.64566 5.35392C4.59917 5.30743 4.56229 5.25224 4.53713 5.1915C4.51197 5.13076 4.49902 5.06566 4.49902 4.99992C4.49902 4.93417 4.51197 4.86907 4.53713 4.80833C4.56229 4.74759 4.59917 4.69241 4.64566 4.64592L7.64566 1.64592Z"
        fill="#828181"
      />
    </svg>
  );
};

export default UploadIcon;
