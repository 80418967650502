import React from 'react';

const MenuItem = (props: { active: boolean; icon: any; title: string }) => {
  const { active, icon: Icon, title } = props;

  return (
    <div className={`py-3 ps-8 mb-5 flex items-center ${active ? 'bg-dblue' : 'sidenav-hover'}`}>
      <Icon fill={active && '#fcfafa'} opacity={!active && '0.4'} strokes={active && '#fcfafa'} />
      <h6 className={`font-poppins font-medium ms-3 mb-0 ${active ? 'text-white' : 'text-slate-400'}`}>{title}</h6>
    </div>
  );
};

export default MenuItem;
