import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Tabs, Alert } from 'flowbite-react';
import { imageUrls } from '../../utils/constants';
import { object, string, ValidationError } from 'yup';
import { AxiosError } from 'axios';
import { Course, Section } from '../../types';
import { customModalTheme } from '../../custom-themes/customModal';
import { customTabTheme } from '../../custom-themes/customTab';
import { useDeleteFlagStore } from '../../utils/store';
import { ToastContainer, toast } from 'react-toastify';
import { CourseEditor, EnrollmentData } from './components';
import { useCourse } from '../../components';
import { useQueryClient } from '@tanstack/react-query';

const LecturerCourse = () => {
  const { id: courseId } = useParams();
  const { data: courseData } = useCourse(courseId || '');
  const queryClient = useQueryClient();
  const setDeletionFlag = useDeleteFlagStore((state: any) => state.setDeletionFlag);
  const dFlag = useDeleteFlagStore((state: any) => state.dFlag);
  const [visible, setVisible] = useState(false);
  const [closable, setClosable] = useState(true);
  const [uploadError, setUploadError] = useState('');
  const [error, setError] = useState('');
  const [sections, setSections] = useState<Section[]>();
  const [sectionValidationErrors, setSectionValidationErrors] = useState<Record<string, string>>();
  const [course, setCourse] = useState<Course>();

  const [sectionForm, setSectionForm] = useState({
    title: '',
    description: '',
  });

  const showErrorToast = () =>
    toast.error('Sorry There Was An Issue Please Try Again Later', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'errorToast',
    });

  const showSectionCreationToast = () =>
    toast.success('Section Created Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'sectionCreationToast',
    });

  const showSectionDeletionToast = () =>
    toast.success('Section Deleted Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'sectionDeletionToast',
    });

  const validationSectionSchema = object({
    title: string().required('A Title Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const handleShow = () => setVisible(true);

  const handleClose = () => {
    setSectionForm({ title: '', description: '' });
    setSectionValidationErrors({});
    setUploadError('');
    setVisible(false);
  };

  const handleChange = (e: any) => {
    setSectionForm({
      ...sectionForm,
      [e.target.name]: e.target.value,
    });
  };

  const createSection = async () => {
    try {
      await validationSectionSchema.validate(sectionForm, { abortEarly: false });
      await axios.post(`/courses/sections`, { ...sectionForm, courseId });
      queryClient.invalidateQueries({ queryKey: ['course'] });
      setClosable(true);
      showSectionCreationToast();
      handleClose();
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setSectionValidationErrors(formErrors);
      } else {
        showErrorToast();
      }
      setClosable(true);
    }
  };

  useEffect(() => {
    if (dFlag) {
      showSectionDeletionToast();
      setDeletionFlag(false);
    }
  }, []);

  return (
    <div>
      <div className="flex flex-col bg-white rounded shadow-sm page-card-container">
        <div className="flex content-center justify-between m-3 rounded bg-dblue">
          <h1 className="self-center p-8 mb-0 text-2xl font-bold text-white capitalize font-lato">
            {courseData?.title}
          </h1>
          {courseData?.sections && courseData.sections.length >= 1 ? (
            <button className="self-center me-8 custom-primary-section-btn" onClick={handleShow}>
              Create Section
            </button>
          ) : null}
        </div>
        <div className="flex-grow px-6 pb-6">
          <Tabs aria-label="Tabs with underline" style="underline" className="h-full border-0" theme={customTabTheme}>
            <Tabs.Item active title="Course Material">
              {error && (
                <Alert color="failure" className="mb-4">
                  <p className="font-medium font-lato">{error}</p>
                </Alert>
              )}
              {courseData?.sections && courseData.sections.length >= 1 ? (
                <div>
                  {courseData.sections.map((section) => (
                    <div key={section._id} className="border-b hover:bg-[#F8F8F8]">
                      <Link
                        to={{
                          pathname: `/lecturer/courses/${courseId}/section/${section._id}`,
                        }}
                        className="text-decoration-none"
                      >
                        <div className="px-6 py-4">
                          <h5 className="text-lg font-semibold capitalize fomt-lato">{section.title}</h5>
                          <p className="mb-1 capitalize font-poppins font-mediumtext-base">{section.description}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col content-center justify-center h-full">
                  <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-2 cursor-pointer h-48" />
                  <p className="mb-1 text-base font-medium text-center capitalize font-lato">No Sections Available</p>
                  <p className="text-sm font-normal text-center capitalize font-lato text-slate-400 mb-6">
                    Sorry, there is no content here at the moment.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="h-12 text-base rounded font-poppins bg-primary w-36 custom-primary-btn"
                      onClick={handleShow}
                    >
                      Create Section
                    </button>
                  </div>
                </div>
              )}
            </Tabs.Item>
            <Tabs.Item title="Settings">
              <CourseEditor />
            </Tabs.Item>
            <Tabs.Item title="Enrollment Data">
              <EnrollmentData courseId={courseId} />
            </Tabs.Item>
          </Tabs>
        </div>
      </div>

      <Modal show={visible} onClose={handleClose} size="md" position="center" theme={customModalTheme}>
        <Modal.Header className="justify-center p-4 border-0">
          <h3 className="font-bold font-lato">Create New Section</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <form>
            <label htmlFor="section_title" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
              Section Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={sectionForm.title}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {sectionValidationErrors?.title && (
              <p className="text-base text-red-600 font-lato">{sectionValidationErrors.title}</p>
            )}

            <label htmlFor="description" className="block pt-2 pb-2 text-lg font-normal text-gray-400 font-lato">
              Section Description
            </label>
            <input
              type="text"
              name="description"
              id="description"
              value={sectionForm.description}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {sectionValidationErrors?.description && (
              <p className="text-base text-red-600 font-lato">{sectionValidationErrors.description}</p>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={handleClose}
            disabled={!closable}
          >
            Close
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins"
            onClick={createSection}
          >
            Create Section
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer containerId="sectionDeletionToast" />
      <ToastContainer containerId="sectionCreationToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};
export default LecturerCourse;
