export enum UserType {
  Instructor = 'INSTRUCTOR',
  Student = 'STUDENT',
  Admin = 'ADMIN',
}

export enum Visibility {
  HIDDEN = 'hidden',
  ENROLLED = 'enrolled',
  PUBLIC = 'public',
}

export type MediaSelection = Record<string, Media>;

export type ProfileData = {
  bio: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  title: string;
  profilePhotoUrl: string;
};

export type Media = {
  title: string;
  type: string;
  url: string;
  size: string;
  creator: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  id: string;
};

export type Section = {
  _id: string;
  title: string;
  description: string;
  media: SectionMedia[];
};

export type SectionMedia = {
  mediaId: string;
  order: number;
  _id: string;
};

export type Course = {
  id: string;
  title: string;
  description: string;
  photoUrl: string;
  price?: number;
  thumbnailUrl?: string;
  creator?: ProfileData;
  visibility: string;
  sections: Section[];
};

export type EnrollmentData = {
  totalEnrollments: number;
  totalEarnings: number;
};
