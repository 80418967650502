/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { MediaViewer } from '../../components';
import { LecturerCourseProgressView } from './components';
import { Course, Media } from '../../types';
import { AxiosError } from 'axios';

const LecturerCourseContent = () => {
  const [error, setError] = useState('');
  const [mediaObject, setMediaObject] = useState<Media>();
  const [courseObject, setCourseObject] = useState<Course>();
  const { mediaId, courseId } = useParams();

  const getMediaObject = async () => {
    try {
      const res = await axios.get(`media/${mediaId}`);
      setMediaObject(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const getCourseObject = async () => {
    try {
      const res = await axios.get(`/courses/${courseId}`);
      setCourseObject(res.data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getMediaObject();
    getCourseObject();
  }, [mediaId]);
  return (
    <div className="bg-white rounded page-card-container">
      <div className="grid gap-4 lg:grid-cols-12 p-6 h-full">
        <div className="lg:col-span-8 h-5/6">
          <MediaViewer mediaObject={mediaObject!} />
        </div>
        <div className="lg:col-span-4">
          <div className="p-3 mb-3 bg-white rounded shadow font-lato font-medium text-lg">
            <h1 className="font-lato font-semibold text-lg mb-0 capitalize">Course content</h1>
          </div>
          <LecturerCourseProgressView courseObject={courseObject} />
        </div>
      </div>
    </div>
  );
};

export default LecturerCourseContent;
