import React, { useState, useEffect } from 'react';

import { List, X } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem, LogoComponent, UserName, ProfilePicture } from '../../../components/index';
import { HelpIcon, ScheduleIcon, UserIcon, LogoutIcon, OverviewIcon, CourseIcon } from '../../../assets/svg-components';
import { logout } from '../../../utils/utilMethods';

const StuHeader = () => {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setShow(false);
  }, [pathname]);

  const handleLogOut = async () => {
    await logout();
  };

  return (
    <div className="bg-white py-3">
      <div className="flex justify-between">
        <List size={25} color="black" className="lg:hidden self-center ms-2" onClick={() => setShow(!show)} />

        <div className="me-8 lg:ms-auto">
          <Link to="/student/profile" className="no-underline flex items-center">
            <img src={ProfilePicture()} className="rounded-full me-2 w-10 h-10" />
            <p className="font-poppins text-base font-medium text-black mb-0">
              <UserName />
            </p>
          </Link>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${
          show ? 'translate-x-0' : '-translate-x-full'
        } bg-white w-64 dark:bg-gray-800`}
      >
        <div className="mb-5 flex justify-between">
          <LogoComponent />
          <X size={30} color="black" className="self-center" onClick={() => setShow(!show)} />
        </div>
        <div className="py-4 overflow-y-auto">
          {/* <Link className="no-underline" to="/student/overview">
            <MenuItem title="Dashboard" icon={OverviewIcon} active={pathname === '/student/overview'} />
          </Link> */}
          <Link className="no-underline" to="/student/course">
            <MenuItem title="Courses" icon={CourseIcon} active={/^\/student\/course(\/|$)/.test(pathname)} />
          </Link>
          {/* <Link className="no-underline" to="/student/schedule">
            <MenuItem title="Schedule" icon={ScheduleIcon} active={pathname === '/student/schedule'} />
          </Link> */}
          <Link className="no-underline" to="/student/profile">
            <MenuItem title="Profile" icon={UserIcon} active={pathname === '/student/profile'} />
          </Link>
          <hr />
          {/* <Link className="no-underline" to="/lecturer/help">
            <MenuItem title="Help" icon={HelpIcon} active={pathname === '/student/help'} />
          </Link> */}
          <div onClick={handleLogOut}>
            <MenuItem active={false} title="LogOut" icon={LogoutIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StuHeader;
